import Link from 'next/link'
import ExtLink from './ext-link'
import { useRouter } from 'next/router'
import styles from '../styles/header.module.css'
import Alexhead from '../components/alex-head'

const navItems: { label: string; page?: string; link?: string }[] = [
  { label: 'Home', page: '/' },
  { label: 'Blog', page: '/blog' },
  { label: 'Contact', page: '/contact' },
  { label: 'Projects', page: '/projects' },
]

type Props = {
  titlePre: string;
  image?: string;
  children?: React.ReactNode;
};

const Header = (props: Props) => {
  const { pathname } = useRouter()

  return (
    <header className={styles.header}>
      <Alexhead {...props}/>
      <h1>AlexThings</h1>
      <ul>
        {navItems.map(({ label, page, link }) => (
          <li key={label}>
            {page ? (
              <Link href={page}>
                <a className={pathname === page ? 'active' : undefined}>
                  {label}
                </a>
              </Link>
            ) : (
              <ExtLink href={link}>{label}</ExtLink>
            )}
          </li>
        ))}
      </ul>
    </header>
  )
};

export default Header;
