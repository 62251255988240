import Head from 'next/head'

type Props = {
  titlePre: string;
  children?: React.ReactNode;
  description?: string;
  image?: string;
};

const Alexhead = ({ children, description, titlePre = '', image = '/og-image.png' }: Props) => {
  return (
    <Head>
      <title>{titlePre}</title>
      <meta name="description" content={(description || "The AlexThings blog")} />
      <meta name="og:title" content={titlePre} />
      <meta property="og:image" content={(image)} />
      <meta name="twitter:site" content="@alexthings1" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={(image)} />
      <script type="text/javascript" src="/bunseki.js"></script>
      <noscript><p><img src="//bunseki.juxt.pro/matomo.php?idsite=3&amp;rec=1"
                        alt="" /></p></noscript>
      {children}
    </Head>
  )
}

export default Alexhead
